.main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  margin-top: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding-bottom: 10px;
}
.topPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}
.time {
  font-size: 10px;
  color: #ffffff;
}
.description {
  font-weight: 300;
  font-size: 14px;
  color: #cecece;
  margin-top: 10px;
}
