.popular {
  display: flex;
  width: 100%;
}
.imgBox {
  width: 300px;
}
.imgBox img {
  width: 100%;
}
.title {
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  margin: 10px 0;
}
.desc {
  font-size: 14px;
  color: #c1c1c1;
  font-weight: 400;
}
.item {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 374px;
  padding: 10px;
  height: 426.59px;
  background: #1c2441;
  box-shadow: 14.025px 14.025px 29.2188px rgb(16 16 18 / 50%),
    -14.025px -14.025px 29.2188px rgb(59 68 81 / 50%);
  border-radius: 9.35px;
}
