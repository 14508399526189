.main {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a2037;
  height: 91vh;
  gap: 50px;
}
.main p {
  margin: 15px;
}
@media only screen and (max-width: 1050px) {
  .main {
    height: 100%;
    flex-wrap: wrap;
  }
}
