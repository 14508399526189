.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 10px;
}
.title svg {
  margin-left: 15px;
}
.main {
  background: #1c2441;
  box-shadow: 12px 12px 25px rgba(16, 16, 18, 0.5),
    -12px -12px 25px rgba(59, 68, 81, 0.5);
  border-radius: 8px;
  height: 450px;
}
.topPart {
  height: 50px;
  background: #3b4568;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.topPart p {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}
.mainArea {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.imgBox {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.notFoundData {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}
