.newsTable {
  width: 100%;

  display: flex;
  text-align: center;
  background: #1c2441;
  box-shadow: 14.025px 14.025px 29.2188px rgba(16, 16, 18, 0.5),
    -14.025px -14.025px 29.2188px rgba(59, 68, 81, 0.5);
  border-radius: 9.35px;
}
.newsTableHeader {
  background: #3b4568;
  border-radius: 8px 8px 0px 0px;
  width: 1562px;
  height: 52px;
}
.newsTableItem {
  /* margin: 10px; */
}
.padding {
  padding: 21px;
}
table,
tr,
td,
th {
  border-collapse: collapse;
}

table {
  width: 100%;
  height: 200px;
  /* border: none !important; */
}
tr {
  border: none !important;
  border-collapse: collapse;
}

th {
  color: #ffffff;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}
.buyBtn {
  width: 100px;
  height: 45px;
  outline: none;
  border: none;
  /* color */

  background: linear-gradient(90deg, #0066ff 0%, #00d1ff 100%);
  border-radius: 50px;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  /* identical to box height */

  color: #ffffff;
}
.sellBtn {
  width: 100px;
  height: 45px;

  border: 1px solid #ff1616;
  border-radius: 20px;
  background: none;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;

  align-items: center;

  color: #ff1616;
}
.thWidth {
  width: 5%;
  padding: 12px;
  background: #1c2441;
  /* background-color: red; */
}
.buyBtn,
.sellBtn:hover {
  cursor: pointer;
}
.trList {
  background: yellow;
}
.vote {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100px;
}
.impact {
}
td {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: #ffffff;
}
