.indices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.bnbArea {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title {
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}
.textArea {
  margin-left: 5px;
}
.desc {
  font-weight: 400;
  font-size: 12px;
  color: #7b8395;
}
.btnArea {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buy {
  width: 55px;
  height: 24px;
  background: linear-gradient(90deg, #0066ff 0%, #00d1ff 100%);
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.sell {
  width: 55px;
  height: 24px;
  border: 1px solid #ff1616;
  box-sizing: border-box;
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  color: #ff1616;
  background-color: #1c2441;
  cursor: pointer;
  margin-left: 10px;
}
