.main {
  display: flex;
  align-items: flex-start;
  color: #fff;
  gap: 40px;
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  /* height: 400px; */
  overflow: hidden;
}

/* .indices {
  overflow-y: scroll;
  height: 300px;
} */

.avaArea {
  position: relative;
  display: flex;
  justify-content: center;
}
.ellips {
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.userName {
  margin-top: 10px;
}
.p1 {
  color: #d2d2d2;
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  margin: 5px 0;
}
.p2 {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
}
.iconsArea {
  display: flex;
  justify-content: center;
}
.paymantArea {
  padding: 0;
}
.cost {
  text-align: center;
  color: #00b04d;
  margin: 0 0 10px 0;
}
.btn {
  width: 89px;
  height: 27px;
  border-radius: 14px;
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  border: none;
}
