.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0 0 10px 10px;
}
.main {
  padding: 10px;
  background: #1c2441;
  box-shadow: 12px 12px 25px rgba(16, 16, 18, 0.5),
    -12px -12px 25px rgba(59, 68, 81, 0.5);
  border-radius: 8px;
  height: 370px;
}
.traders {
  background: #1c2441;
  border-radius: 8px;
  box-shadow: 12px 12px 25px rgba(16, 16, 18, 0.5),
    -12px -12px 25px rgba(59, 68, 81, 0.5);
  height: 400px;
  overflow-y: scroll;
}
