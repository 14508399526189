.title {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
}
.item {
  width: 420px;
  /* height: 710px; */
  background: #1c2441;
  box-shadow: 12px 12px 25px rgba(16, 16, 18, 0.5),
    -12px -12px 25px rgba(59, 68, 81, 0.5);
  border-radius: 8px;
  padding: 25px;
  cursor: pointer;
}
.eachElement {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.line {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.item p {
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
}
.cost {
  font-weight: bold;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
}
