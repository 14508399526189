.high {
  background: rgba(254, 48, 35, 0.1);
}
.medium {
  background: rgba(242, 149, 64, 0.15);
}
.low {
  background: rgba(80, 176, 72, 0.1);
}

.red {
  text-align: center;
  display: block;
  margin-left: 11px;
  height: 26px;
  background: #fe3023;
  border-radius: 4px;
  color: #ffffff;
}
.yellow {
  text-align: center;
  display: block;
  margin-left: 11px;
  height: 26px;
  background: #f29540;
  border-radius: 4px;
  color: #ffffff;
}
.green {
  text-align: center;
  display: block;
  margin-left: 11px;
  height: 26px;
  background: #50b048;
  border-radius: 4px;
  color: #ffffff;
}
