.page {
  display: grid;
  grid-template-areas: "n h" "n m";
  grid-template-columns: 290px 1fr;
  grid-template-rows: 75px 1fr;
}

.header {
  grid-area: h;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #1c2441;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px 0px 0px 8px;
}

.headMainPart {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}
.searchLab {
  position: relative;
}
.selectingLab {
  position: relative;
}
.selectingLab input {
  width: 583px;
  height: 40px;
  background: #3b4568;
  border-radius: 20px;
  padding-left: 50px;
  border: none;
  color: #d2d2d2;
}
.searchLab input {
  width: 320px;
  height: 40px;
  background: #3b4568;
  border-radius: 20px;
  padding-left: 50px;
  border: none;
  color: #d2d2d2;
}
.selectingLab svg {
  position: absolute;
  top: -2px;
  left: 10px;
}
.searchLab svg {
  position: absolute;
  top: -2px;
  left: 10px;
}
.notify {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  height: 40px;
  background: #3b4568;
  border-radius: 20px;
  padding: 0 20px;
  color: #d2d2d2;
}
/* Navigate */
.openMenu {
  padding: 10px 0;
  display: none;
  transform: rotate(180deg);
}
.openMenu svg {
  cursor: pointer;
}
.navigate {
  grid-area: n;
  background: #1c2441;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.25);
  color: #fff;
  min-height: 100vh;
}
.navigate p {
  margin: 0;
}
.navHeadPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.closeMenu {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.closeMenu svg {
  cursor: pointer;
}
/* .userArea {
} */
.userInfo {
  display: grid;
  place-items: center;
  padding: 0;
  margin: 0;
}
.userName {
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}
.profileInfo {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  color: #679ef0;
  margin: 0;
}
.followArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 35px;
}
.menuItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  height: 50px;
  padding-left: 20px;
  cursor: pointer;
}
.menuItem a {
  margin-left: 20px;
  text-decoration: none;
  color: #fff;
}
.bottomPart {
  margin-top: 250px;
}
.activeItem {
  background: linear-gradient(90deg, #0066ff 0%, #00d1ff 100%);
}
.activeItem span {
  color: #fff;
}
.activeItem svg path {
  fill: #fff;
}
.main {
  grid-area: m;
  padding: 15px;
}
