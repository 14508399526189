.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0 0 10px 10px;
}
.traders {
  overflow-y: scroll;
  background: #1c2441;
  border-radius: 8px;
  box-shadow: 12px 12px 25px rgba(16, 16, 18, 0.5),
    -12px -12px 25px rgba(59, 68, 81, 0.5);
  height: 300px;
}

::-webkit-scrollbar {
  width: 1px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* -webkit-border-radius: 10px;
  border-radius: 10px; */
}
