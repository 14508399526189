.title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0 0 10px 10px;
}
.main {
  padding: 10px;
  background: #1c2441;
  box-shadow: 12px 12px 25px rgba(16, 16, 18, 0.5),
    -12px -12px 25px rgba(59, 68, 81, 0.5);
  border-radius: 8px;
  height: 283px;
}
.topPart {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add {
  padding: 10px 15px;
  background-color: #3b4568;
  border: none;
  border-radius: 20px;
  color: #d2d2d2;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.select {
  padding: 10px;
  background-color: #3b4568;
  border: none;
  color: #d2d2d2;
}
.inp {
  background-color: #3b4568;
  border: none;
  outline: none;
  color: #fff;
  width: 100px;
}
.itemsArea {
  margin-top: 20px;
}
