.main {
  border-top: 1px solid rgba(255, 255, 255, 0.6);
}
.item {
  margin-top: 40px;
}
.title {
  font-weight: 300;
  font-size: 16px;
  color: #cacaca;
}
.count {
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  margin-top: 5px;
}
.count span {
  font-weight: 400;
  font-size: 9px;
  color: #cacaca;
  margin-left: 5px;
}
